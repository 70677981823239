<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">

            <!-- Forgot Password v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <!-- logo -->
                    <vuexy-logo />

                    <h2 class="brand-text text-primary ml-1">
                        Smart ADM
                    </h2>
                </b-link>

                <b-card-title class="mb-1">
                    Esqueceu sua Senha? 🔒
                </b-card-title>
                <b-card-text class="mb-2" v-if="!emailEnviado">
                   Entre com seu Email de acesso, para receber instruções para Redefinir a senha!
                </b-card-text>

                <b-alert variant="danger" v-if="errors" show>
                    <div class="alert-body" v-for="(error, index) in errors" :key="index">
                         {{error[0]}}
                    </div>
                </b-alert>

                <b-alert variant="danger" v-if="errorsNaoEncontrado" show>
                    <div class="alert-body">
                        {{ errorsNaoEncontrado }}
                    </div>
                </b-alert>

                <b-alert variant="success" v-if="emailEnviado" show>
                    <div class="alert-body">
                        Enviamos um email para <span class="alert-link"> {{ email }} </span>, com as informações para redefinir a senha!
                    </div>
                </b-alert>

                <!-- form -->
                <validation-observer ref="simpleRules">
                    <b-form
                        class="auth-forgot-password-form mt-2"
                        @submit.prevent="validationForm"
                    >
                        <!-- email -->
                        <b-form-group
                            label="Email"
                            label-for="forgot-password-email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="forgot-password-email"
                                    v-model="userEmail"
                                    :state="errors.length > 0 ? false:null"
                                    name="forgot-password-email"
                                    placeholder="Digite o seu email"
                                    :disabled="proDisabled"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button  variant="primary" block type="submit" :disabled="proDisabled">
                            <b-spinner small v-if="spinner" />
                            <span v-if="spinner">Aguarde...</span>
                            <span v-else> Enviar Link</span>
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <b-link :to="{name:'login'}">
                        <feather-icon icon="ChevronLeftIcon" /> Voltar para login
                    </b-link>
                </b-card-text>

            </b-card>
            <!-- /Forgot Password v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BAlert, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
    components: {
        VuexyLogo,
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BFormGroup,
        BFormInput,
        BButton,
        BForm,
        BAlert,
        BSpinner,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userEmail: '',
            errors: [],
            errorsNaoEncontrado: '',
            emailEnviado: false,
            spinner: false,
            proDisabled: false,

            // validation
            required,
            email,
        }
    },
    methods: {
        validationForm() {



           this.$refs.simpleRules.validate().then(success => {
                if (success) {

                   this.spinner = true
                   this.proDisabled = true

                   this.$http.post('v1/forgot-password', {'email': this.userEmail}).then( res => {

                       this.limparError()
                       this.email = this.userEmail
                       this.emailEnviado = true
                       this.spinner = false


                   })
                    .catch( error => {
                        this.spinner = false
                        this.proDisabled = false

                        if(error.response.status == 422)
                        {
                            this.errors = error.response.data.errors
                        }

                        if(error.response.status == 404)
                        {
                            this.errorsNaoEncontrado = 'Email não cadastrado em nossos registros de Usuários!'
                        }

                    })
                }
            })
        },

        limparError(){
            this.errors = []
            this.errorsNaoEncontrado = ''
        },
    },


}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
